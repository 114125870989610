main#page-home {
  .b-banner {
    h1 {
      font-weight: 600;
    }

    img {
      min-height: 37.5rem;
      object-position: left;
      @include media-breakpoint-down(md) {
        min-height: 42rem;
      }

    }

    &-overlay {
      @extend %coverer;
      z-index: 10;
      background: rgba($dark, 0.3);
    }
  }

  .latest-news-wrapper::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 116px;
    background: $white;
    z-index: 5;
  }

  .latest-news {
    position: relative;
    display: block;
    width: 380px;
    background: $primary;
    border-radius: 2rem 2rem 2rem 0;
    z-index: 10;

    &:hover {
      .arrow-btn {
        span {
          background-color: $secondary;
          transform: rotate(-35deg);

          svg {
            fill: $white;
          }
        }
      }
    }
  }


  .b-event {
    color: $text;
    text-decoration: none;

    img {
      aspect-ratio: 1 / 1;
    }

    h3 {
      line-height: 1.7;
    }

    &-date {
      width: 4.5rem;
      height: 4.5rem;
      background: $primary;
      line-height: 1.5rem;
      border-radius: $border-radius-lg;
      position: absolute;
      bottom: 1rem;
      left: 1rem;

      span:first-of-type {
        @include font-size(28px);
      }
    }

    .btn-article {
      transition: all ease-out 0.5s;
    }

    &:hover {
      .btn-article {
        span {
          border-color: $primary;
          background: $primary;
          transform: rotate(-35deg);

          svg {
            fill: $white;
          }
        }
      }
    }
  }

  .b-event-sm {
    .img-box {
      aspect-ratio: 1 / 1;
      display: flex;
      align-items: flex-end;
    }

    img {
      aspect-ratio: 14 / 9;
    }
  }

  .b-usp {
    &-icon {
      width: 3rem;
      height: 3rem;
    }

    h4 {
      @include media-breakpoint-down(lg) {
        max-width: 15rem;
      }
    }
  }

  .b-about-cta {
    background-image: url('/static/masks/mask-usp.svg');
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 22.406rem;
    height: 12.844rem;
    @include media-breakpoint-down(sm) {
      position: relative;
      background: none;
      width: auto;
      height: auto;
      //padding: 1rem;
      //margin-top: -2rem;
      //margin-right: 1rem;
      //margin-left: -0.1rem;
      //position: relative;
      //background: $white;
      //border-radius: 0 2rem 0 0;

    }

    span {
      width: 2rem;
      height: 2rem;
      background: $text;
      border-radius: 100%;
      transition: $transition-base;
    }

    a {
      position: absolute;
      right: 3rem;
      bottom: 3rem;
      @include media-breakpoint-down(sm) {
        position: relative;
        right: 0;
        bottom: 0;
        margin-top: 3rem;
      }

      &:hover {
        span {
          background: $primary;
          transform: rotate(-35deg);
        }
      }
    }
  }

  .b-head-sponsors {
    background: linear-gradient(0deg, $light 80%, rgba(255, 255, 255, 0) 80%);

    .e-sponsortabs {
      img {
        max-height: 70px;
        object-fit: contain;
      }

      #sponsor-swiper-thumbs {
        border-top: 1px solid $gray-300;

        .swiper-slide {
          margin-top: -1px;
          border-top: 3px solid rgba(0, 0, 0, 0);

          img {
            mix-blend-mode: multiply;
            filter: grayscale(100%);
            opacity: 0.5;
            transition: $transition-base;
          }

          &:hover {
            img {
              filter: none;
              opacity: 1;
            }
          }
        }

        .swiper-slide-thumb-active {
          transition: $transition-base;
          border-top: 3px solid $primary;

          img {
            filter: none;
            opacity: 1;
          }
        }
      }
    }
  }
}

main#page-news-overview{
  .news-filter-tabs{
    background: $light;
    color: $gray-600;
    transition: $transition-base;
    &:hover, &:active, &.active{
      background: $primary;
      color: $white!important;
    }
  }
}

.a-hover-zoom-img {
  display: inline-block;
  overflow: hidden;

  img {
    transition: transform 0.6s ease;
  }

  &:hover img {
    transform: scale(1.05);
    transition-duration: 3s;
  }
}

.sponsor-bg {
  background: linear-gradient(90deg, rgba($light, 1) 65%, rgba(255, 255, 255, 0) 65%);
}

.sponsors {
  z-index: 20;
  height: 100px;
}

.sponsor-label {
  position: absolute;
  top: 0;
  left: -45px;
  right: 0;
  transform: translateY(-100%);
  z-index: 30;
}

.sponsor-label-text {
  mask: url('/static/masks/mask-sponsor-banner.svg');
  mask-size: contain;
  mask-repeat: no-repeat;
}

.sponsor-marquee-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  //width: 100%;
}

.sponsor-marquee {
  display: flex;
  animation: marquee linear infinite;
  white-space: nowrap;

  &:hover {
    animation-play-state: paused;
  }

  .sponsor-marquee-row {
    transition-timing-function: linear;
  }

  .sponsor-marquee-item {
    background: $light;
    width: auto;
    margin-right: 64px;
  }

  img {
    max-width: 8rem;
    height: 5rem;
    object-fit: contain;
    mix-blend-mode: multiply;
    filter: grayscale(100%);
    // transition: $transition-base;
    padding: 1rem;

    &:hover {
      filter: none;
    }
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%)
  }
}

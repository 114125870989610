#page-help-center-overview {
  .section-faq-categories {
    background: linear-gradient(to bottom, $light 0, $light 60px, $body-bg 50px, $body-bg);
  }

  #search-help-center {
    &:focus {
      box-shadow: none;
    }

    &:focus.has-matches {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-color: $dropdown-border-color;
    }
  }

  #list-search-results {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .highlight {
      font-weight: 700;
    }
  }
}

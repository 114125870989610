.l-site-footer {
  img.logo {
    max-width: 24rem;
  }

  ul {
    li a {
      color: $text;
      transition: $transition-base;
      text-decoration: none;
      font-weight: 300;

      &:hover {
        color: $primary;
      }
    }
  }

  .social-icon {
    svg {
      transition: $transition-base;
    }

    &:hover {
      svg {
        background: $primary;
        fill: $white;
        border-color: $primary;
      }
    }
  }

  .author-link {
    svg {
      transition: $transition-base;
    }

    &:hover svg {
      fill: $primary;
    }
  }

  .sponsor-label {
    display: none;
  }

  .footer-sponsors{
    height: 100px;
  }

  .sponsor-marquee-wrapper {
    .sponsor-marquee {
      width: 100%;
    }
  }
}

main#page-contact {
  #map {
    width: 100%;
    height: 21.25rem;
  }

  .b-hospital-info {
    border-radius: 2rem 2rem 2rem 0;

    a {
      transition: $transition-base;

      svg {
        transition: $transition-base;
        margin-right: 5px;
        margin-top: 2px;
      }

      &:hover {
        color: $primary !important;

        svg {
          fill: $primary
        }
      }
    }
  }

  .swiper-nav-block {
    position: absolute;
    top: 1.5rem;
    right: 0;

    div {
      width: 3rem;
      height: 3rem;
      position: relative;

      &::after {
        content: '';
      }
    }
  }

  .b-team {
    a {
      transition: $transition-base;

      svg {
        transition: $transition-base;
        margin-right: 5px;
        margin-top: 2px;
      }

      &:hover {
        color: $primary !important;

        svg {
          fill: $primary
        }
      }
    }
  }

  .c-contact {
    &-mask {
      width: 6.625rem;
      height: 6.625rem;
      mask: url('/static/masks/mask-event-button.svg');
      mask-size: contain;
      mask-repeat: no-repeat;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &-form {
      @include media-breakpoint-down(lg) {
        -webkit-box-shadow: 0px 4px 40px -15px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 4px 40px -15px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 40px -15px rgba(0, 0, 0, 0.2);
        padding: 3rem;
      }
      @include media-breakpoint-down(sm) {
        padding: 3rem 2rem;
        max-width: 90%;
      }
    }
  }
}

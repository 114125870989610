main#page-events-subscription {
  min-height: 100vh;

  .navbar-brand img {
    width: 100%;
    max-width: 6rem;
    @include media-breakpoint-up(md) {
      max-width: 9rem;
    }
  }

  .nav-bg {
    background-color: $primary;
    color: $white;
  }

  .s-subscription {
    border-radius: 1.5rem 1.5rem 0 0;

    .account-nr {
      span {
        padding: 0.2rem 1rem;
        border-radius: 0.5rem;
      }
    }

    .payment-info {
      border-radius: 0.5rem;
      padding: 1rem;
    }

    form {
      .btn {
        box-sizing: border-box;
        width: 100%;
        text-align: center;
        padding: 1rem 2rem;
        border-radius: 0.5rem;
        color: $white;
        @include font-size(18px);
        transition: all ease 0.1s;
      }

      .btn-success {
        background-color: $primary;

        &:focus {
          animation-name: buttonClick;
          animation-duration: 0.1s;
          outline: none;
        }
      }

      .btn-danger {
        background-color: $danger;

        &:focus {
          animation-name: buttonClick;
          animation-duration: 0.1s;
          outline: none;
        }
      }

      @keyframes buttonClick {
        0% {
          box-shadow: none;
          transform: translateY(0);
        }
        50% {
          -webkit-box-shadow: inset 0px 5px 12px 0px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: inset 0px 5px 12px 0px rgba(0, 0, 0, 0.15);
          box-shadow: inset 0px 5px 12px 0px rgba(0, 0, 0, 0.30);
          transform: translateY(2px);
        }
        100% {
          box-shadow: none;
          transform: translateY(0);
        }
      }
    }

    .p-info {
      background-color: rgba($info, 0.1);
      border: 1px solid rgba($info, 0.3);
      padding: 0.8rem 1rem;
      border-radius: 0.5rem;
      gap: 0.5rem;

      svg {
        margin-top: 2px;
      }
    }

    .alert {
      margin-top: 1rem;

      .btn-close {
        background-size: 0.8rem;
        opacity: 0.5;
      }

      &.active {
        display: block;
      }
    }
  }
}

textarea.form-control {
  min-height: 10rem;
}

.mail-icon {
  padding: 0.5rem;
}

.form-group-icon {
  $icon-size: 24px;

  position: relative;

  .form-control {
    padding-left: calc($icon-size + ($input-padding-x * 2));
  }

  .form-control-icon-prefix svg {
    width: $icon-size;
    height: $icon-size;
  }
}

.form-control-icon-prefix {
  position: absolute;
  left: 1px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
  padding: 0 $input-padding-x;
  border: 0;
}


.form-check-input[type='radio'] {
  &:checked{
    background-image: none;
    border-color: $white;
    border-width: 2px;
    outline: 1px solid $primary;
  }
}


#subscriber-form {
  .form-control, .input-group-text {
    border: 0;
    border-bottom: 1px solid $gray-300;
    border-radius: 0;

    &::placeholder {
      color: $gray-500;
      font-weight: 300;
    }
  }

  button {
    flex: 1;
  }
}

form:not(#subscriber-form) {
  label {
    @include font-size(14px);
    letter-spacing: 1px;
  }

  .form-control, .form-select {
    letter-spacing: 1px;
  }
}

.password-input {
  input[type="password"] ~ .toggle-password .icon--hide {
    display: none;
  }

  input[type="text"] ~ .toggle-password .icon--show {
    display: none;
  }

  .toggle-password {
    all: unset;
    border: 1px solid #ced4da;
    border-radius: 0 10px 10px 0;
    transition: $transition-base;

    &:hover {
      background: $light;

      svg {
        fill: $primary;
      }
    }

    svg {
      transition: $transition-base;
    }
  }

  .pass-success, .pass-danger {
    position: absolute;
    top: 1.45rem;
    right: 3rem;
    transform: translateY(-50%);
  }
}


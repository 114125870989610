.l-site-nav {
  $nav-breakpoint: 'lg';
  $offcanvas-top: 0; // could also be navbar height
  $offcanvas-bg-color: $text;
  $offcanvas-y: 2.5rem;
  $offcanvas-nav-link-padding-x: 0.625rem;
  $offcanvas-nav-link-padding-y: 1.625rem;
  $offcanvas-active-bg: $primary;

  position: relative;
  z-index: $zindex-offcanvas;
  background: none;

  // prevents inconsistent hover behavior
  a:not(:hover):not(:focus),
  a:not(:hover):not(:focus) svg,
  button:not(:hover):not(:focus),
  button:not(:hover):not(:focus) svg {
    transition: none !important;
  }

  .navbar-brand img {
    height: 4rem;
  }

  .active {
    @include media-breakpoint-up($nav-breakpoint) {
      color: $primary !important;
      //background: $primary;
      border-radius: $border-radius-xl;
    }
    @include media-breakpoint-down($nav-breakpoint) {
      color: $primary !important;
    }
  }

  @include media-breakpoint-up($nav-breakpoint) {
    .nav-link {
      padding: 0.2rem 0 0.5rem 0;
    }
  }
  @include media-breakpoint-up($nav-breakpoint) {
    .nav-link.active:hover {
      &:hover {
        color: $primary !important;
      }
    }
  }

  #site-logo-b {
    display: none;
  }

  .hamburger {
    all: unset;
    cursor: pointer;
    width: 3.25rem;
    height: 3.25rem;
    background-color: $primary;
    border-radius: 12px;

    &:hover {
      background-color: $secondary;
    }
  }

  .btn-search {
    width: 2.875rem;
    height: 2.875rem;
    //background: $white;
    border: 1px solid $white;
    border-radius: 12px;

    &:hover {
      background: $secondary !important;
      border-color: $secondary;

      svg {
        fill: $white;
      }
    }
  }

  .offcanvas {
    border-left: 0;

    @include media-breakpoint-up($nav-breakpoint) {
      position: static;
      visibility: visible !important;
      background: none;
      border: 0;
      transform: none;
      width: auto;
      margin-left: auto;
    }


    @include media-breakpoint-down($nav-breakpoint) {
      background-color: $offcanvas-bg-color;
      padding-top: $offcanvas-y;
      padding-bottom: $offcanvas-y;

      .nav-link {
        color: $white;
        border-bottom: 1px solid $gray-700;
        padding: 0.652rem 0 !important;
        margin: 0 1.652rem 1rem;
        @include font-size(24px);
        font-weight: 600;
      }

      .btn-green {
        margin: 0.652rem 1.652rem 1rem;
        width: auto;
      }

      .close-offcanvas {
        padding: 1rem;
        background: $primary;
        border-radius: $border-radius-lg;

        &:hover {
          background: $secondary;
        }
      }
    }
  }

  .offcanvas .btn-close {
    @include media-breakpoint-up($nav-breakpoint) {
      display: none;
    }
  }

  .offcanvas .nav-link {
    color: $white;

    &:hover {
      color: $primary !important;
    }

    @include media-breakpoint-down($nav-breakpoint) {
      padding: $offcanvas-nav-link-padding-x $offcanvas-nav-link-padding-y;

      &.active {
      }
    }
  }
}

.active-bg {
  background-color: $white;

  #site-logo-w {
    display: none;
  }

  #site-logo-b {
    display: block;
  }

  .offcanvas .nav-link {
    color: $text;
  }

  .btn-search {
    background: $primary;

    svg {
      fill: $white;
    }
  }

  .btn-white {
    border-color: $dark;
    color: $dark;

    .btn-svg {
      background: $primary;

      svg {
        fill: $white;
      }
    }

    &:hover {
      color: $white;
      background: $primary;
      border-color: $primary;

      .btn-svg {
        background: $secondary;

        svg {
          fill: $white;
        }
      }
    }
  }

  .offcanvas.show .nav-link {
    color: $white;
  }

}

@include media-breakpoint-up(lg) {
  .hover-bg {
    background-color: $white;
    transition: $transition-base;

    #site-logo-w {
      display: none;
    }

    #site-logo-b {
      display: block;
    }

    .offcanvas .nav-link {
      color: $text;
    }

    .btn-search {
      background: $primary;

      svg {
        fill: $white;
      }
    }

    .btn-white {
      border-color: $dark;
      color: $dark;

      .btn-svg {
        background: $primary;

        svg {
          fill: $white;
        }
      }

      &:hover {
        color: $white;
        background: $primary;
        border-color: $primary;

        .btn-svg {
          background: $secondary;

          svg {
            fill: $white;
          }
        }
      }
    }
  }
}

#collapseSearch {
  position: absolute;
  top: 6rem;
  border-top: 1px solid $border-color;
}

#search-form {
  #search-label {
    background: none;
    border-radius: 0.5rem 0 0 0.5rem;
    transition: $transition-base;

    &:hover {
      background: $primary;
      border-color: $primary;

      svg {
        fill: $white;
      }
    }
  }

  #search-input {
    &::placeholder {
      color: $text;
      letter-spacing: 2px;
    }
  }
}

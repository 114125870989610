main#page-sponsors{
  .b-sponsor-container{
    &-link{
      img{
        width: 10.25rem;
        height: 4rem;
        object-fit: contain;
      }
      span{
        width: 2rem;
        height: 2rem;
        border-radius: 100%;
        background: $text;
        position: absolute;
        right: 1rem;
        bottom: -3rem;
        opacity: 0;
        transition: bottom 0.2s ease, background 0.3s ease-out, transform 0.2s ease-out;
      }
      &:hover{
        span{
          bottom: 1rem;
          opacity: 1;
          background: $primary;
          transform: rotate(-35deg);
        }
      }
    }
  }
}

.w-banner {
  &-bg {
    height: 25rem;
    background: linear-gradient(0deg, $light 90%, rgba(255, 255, 255, 0) 90%);
  }

  &-title-block {
    -webkit-box-shadow: 0px 4px 40px -15px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 40px -15px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 40px -15px rgba(0, 0, 0, 0.1);
    margin-top: -16rem;
    @include media-breakpoint-down(sm) {
      //margin-top: -22rem;
      max-width: 95%;
    }
  }
}

.w-banner-green {
  &-gradient {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 55%;
    @include media-breakpoint-down(xl) {
      //width: 45rem;
    }
    height: 25rem;
    background: linear-gradient(30deg, $secondary 0%, $primary 50%);
    border-radius: 0 4rem 4rem 0;
  }
}

.w-imgbanner {
  &-top {
    height: 25rem;

    img {
      width: 100%;
      height: 25rem;
    }

    &.w-imgbanner-sm {
      @include media-breakpoint-down(md) {
        height: 10rem;

        img {
          height: 10rem;
        }
      }
    }
  }
}

.w-title-block {
  -webkit-box-shadow: 0px 4px 40px -15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 40px -15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 40px -15px rgba(0, 0, 0, 0.1);
  margin-top: -12rem;
  @include media-breakpoint-down(lg) {
    margin-top: -16rem;
  }
  @include media-breakpoint-down(sm) {
    max-width: 96%;
  }

  &-col {
    min-height: 100%;
    display: grid;
    overflow: hidden;

    .w-title-block-img {
      height: 100%;
    }

    @include media-breakpoint-down(sm) {
      .c-event-date {
        position: unset;
      }
    }
  }

  &-img {
    aspect-ratio: 5 / 4.5;

    &-mask {
      width: 19.464rem;
      height: 12.117rem;
      background: $white;
      position: absolute;
      right: 0;
      bottom: 0;
      mask: url('/static/masks/mask-event-heading.svg');
      mask-repeat: no-repeat;
      mask-size: auto;
    }
  }
}

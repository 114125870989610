.list-group.list-group-faq .list-group-item.list-group-item-action {
  font-weight: 300;
  transition: 0.25s ease;
  border-color: $border-color;

  &:has(+ :hover),
  &:has(+ :focus) {
    border-color: transparent;
  }

  &:hover,
  &:focus {
    border-radius: $border-radius-lg;
    border-color: transparent;
  }

  &:hover {
    background-color: $light;
  }

  &:focus:not(:hover) {
    background-color: $gray-300;
  }
}

// icon
.list-group.list-group-faq .list-group-item.list-group-item-action .icon-wrapper {
  $size: 26px;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;

  @media (pointer: fine) {
    transform: translateX(-5px);
  }

  &,
  svg {
    transition: 0.25s ease;
  }

  svg {
    fill: $dark;
  }
}

.list-group.list-group-faq .list-group-item.list-group-item-action:hover .icon-wrapper,
.list-group.list-group-faq .list-group-item.list-group-item-action:focus .icon-wrapper {
  transform: translateX(0);

  svg {
    fill: $white;
  }
}

// icon - default
.list-group.list-group-faq .list-group-item-default.list-group-item-action .icon-wrapper {
  background: $light;
}

.list-group.list-group-faq .list-group-item-default.list-group-item-action:hover .icon-wrapper,
.list-group.list-group-faq .list-group-item-default.list-group-item-action:focus .icon-wrapper {
  background: $primary;
}

// icon - light
.list-group.list-group-faq .list-group-item-light.list-group-item-action .icon-wrapper {
  background: $light;
}

.list-group.list-group-faq .list-group-item-light.list-group-item-action:hover .icon-wrapper,
.list-group.list-group-faq .list-group-item-light.list-group-item-action:focus .icon-wrapper {
  background: $primary;
}

// list group item - light
.list-group.list-group-faq .list-group-item-light.list-group-item-action {
  background-color: $white;
  border-radius: $border-radius-lg;
  margin-bottom: 0.5rem;

  &:hover{
    background-color: $lighter;
  }
}

.c-event {
  color: $text;

  .img-wrapper{
    border: 1px solid $gray-200;
  }

  img {
    border-radius: $border-radius-xl;
    aspect-ratio: 5 / 4;
  }

  &-date {
    width: 4.5rem;
    height: 4.5rem;
    background: $primary;
    line-height: 1.5rem;
    border-radius: $border-radius-lg;
    position: absolute;
    bottom: 1rem;
    left: 1rem;

    span:first-of-type {
      @include font-size(28px);
    }
  }

  &-subdate{
    @include font-size(13px);
    margin-bottom: 5px;
  }

  h3{
    line-height: 1.7;
  }

  .btn-article {
    transition: all ease-out 0.4s;
  }

  &:hover {
    .btn-article {
      span {
        border-color: $primary;
        background: $primary;
        transform: rotate(-35deg);

        svg {
          fill: $white;
        }
      }
    }
  }

  &:hover{
    color: $text;
  }
}

.c-event-tag{
  color: $text;
  padding: 0.3rem 1rem 0.5rem 1rem;
  transition: $transition-base;
  &:hover{
    background: $primary!important;
    color: $white;
  }
}

.c-warning-tag{
  position: absolute;
  z-index: 100;
  top: 1rem;
  left: 1rem;
  background: $primary;
  color: $white;
  padding: 0.2rem 1.5rem 0.4rem 1.5rem;
  border-radius: 24px;
  @include font-size(14px);
}

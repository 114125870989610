.c-dashboard {
  margin-top: -20rem;

  &-nav, &-help, &-body {
    -webkit-box-shadow: 0px 4px 40px -15px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 40px -15px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 40px -15px rgba(0, 0, 0, 0.1);
  }

  &-navlinks {
    li {
      transition: $transition-base;

      &.warning {
        background-color: rgba($warning, 0.2);
        border-radius: 10rem 0 0 10rem;

        a {
          svg {
            fill: $warning;
          }

          &:hover, &.active-dashboard {
            color: $warning;

            &:after {
              background-color: $warning;
              opacity: 1;
            }
          }
        }
      }

      a {
        padding: 0.8rem 1.5rem;
        transition: $transition-base;

        &::after {
          transition: $transition-base;
          content: '';
          width: 4px;
          height: 4px;
          background: $primary;
          border-radius: 100%;
          margin-left: 2rem;
          margin-top: 4px;
          opacity: 0;
        }

        &:hover, &.active-dashboard {
          color: $primary;

          &:after {
            opacity: 1;
          }
        }
      }
    }

  }

  &-last-login {
    span {
      &:before, &:after {
        content: '';
        width: 4px;
        height: 4px;
        background: $primary;
        border-radius: 100%;
        margin-top: 4px;
      }
    }
  }

  &-body {
    height: 53.5rem;
    @include media-breakpoint-down(lg) {
      height: 57.1rem;
    }
  }

  .account-type {
    .alert {
      padding: 0.2rem 1rem;
      border-radius: 10rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      transition: $transition-base;
    }

    .alert-warning {
      &:hover, &:focus {
        color: $warning;
      }
    }
  }

  .subscribe-form {
    width: 100%;
  }
}

// SIDEBAR NAV / OFFCANVAS
.c-dashboard-offcanvas {
  transition: $transition-base;
  min-width: 20rem;
  transform: translateX(0);

  @include media-breakpoint-down(lg) {
    -webkit-box-shadow: 5px 0px 23px -15px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 0px 23px -15px rgba(0, 0, 0, 0.75);
    box-shadow: 5px 0px 23px -15px rgba(0, 0, 0, 0.75);

    transform: translateX(-20rem);

    div {
      box-shadow: none;
    }
    position: absolute;
    padding-top: 2rem;
    top: 0;
    left: 0rem;
    background: #fff;
    border-radius: 0 1.5rem 1.5rem 0;
    z-index: 2000;
    overflow-y: auto;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    transform: translateX(-100%);
  }
}

.c-dashboard-offcanvas.show-offcanvas {
  transform: translateX(0);
}

#dashboard-nav-toggle {
  background: $primary;
}

#dashboard-nav-close {
  background: $primary;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  margin-left: auto;
  margin-right: 2rem;
}

#dashboard-doc-tabs {
  border: none;

  .nav-item {
    .nav-link {
      color: $gray-600;
      border: none;
      background: $light;
      border-radius: $border-radius-lg;
      @include font-size(14px);
      padding: 0.5rem 2rem;

      &:hover {
        background: $primary;
        color: $white;
      }
    }

    .active {
      background: $primary;
      color: $white !important;
    }
  }
}


// TABLES
.c-dashboard-table {
  tbody {
    vertical-align: middle;
  }

  &-action {
    width: 1.75rem;
    height: 1.75rem;
    background: $primary;
    transition: $transition-base;

    &:hover, &:focus {
      background: $secondary;
    }

    &:after {
      display: none;
    }
  }

  &-downloads {
    min-width: 7rem !important;
    max-width: 7rem !important;
    @include media-breakpoint-down(md) {
      position: sticky;
      right: -1px;
    }
  }

  .download-toggled {
    z-index: 1000;
  }

  &-price {
    white-space: nowrap;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .dropdown-item {
    font-weight: 300;

    &:hover {
      background: none;
      color: $primary;
    }
  }
}

.card.card-faq-category {
  width: 100%;
  min-width: 12.5rem;
  text-decoration: none;
  transition: 0.25s;
  border-radius: $border-radius-xl;
  border-color: $border-color;

  @include media-breakpoint-up(sm) {
    width: calc(50% - #{$spacer});
    max-width: 16rem;
  }

  @include media-breakpoint-up(md) {
    width: calc(33% - #{$spacer * 2});
  }

  &:hover {
    border-color: $primary;
    //box-shadow: inset 0 0 0 2px $primary;
  }

  .card-body {
    padding: 1.5rem;
  }

  .category-name {
    font-weight: 700;
    text-wrap: balance;
  }
}

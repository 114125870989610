.btn {
  all: unset;
  cursor: pointer;
  transition: $transition-base;
}

.btn-custom {
  min-width: 10rem;
  padding: 0.5rem 0.75rem 0.75rem 0.75rem;
  border: 1px solid;
  border-radius: 10px;
  justify-content: space-between;
  gap: 1rem;

  .btn-text {
    line-height: 1.5rem;
  }

  .btn-svg {
    margin-bottom: -0.1rem;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    border-radius: 100%;
    transition: $transition-base;

    svg {
      transition: $transition-base;
    }
  }

  &:hover {
    svg {
      transform: rotate(-35deg);
    }
  }
}

.btn-return {
  transition: $transition-base;

  svg {
    margin-top: 2px;
    margin-right: 5px;
    transition: $transition-base;
  }

  &:hover {
    transform: translateX(-5px);
    color: $primary;

    svg {
      fill: $primary;
    }
  }
}

.btn-white {
  border-color: $white;
  color: $white;

  .btn-svg {
    background: $primary;

    svg {
      fill: $white;
    }
  }

  &:hover, &:focus {
    color: $white;
    background: $primary;
    border-color: $primary;

    .btn-svg {
      background: $secondary;

      svg {
        fill: $white;
      }
    }
  }
}

.btn-dark {
  border-color: $gray-400;
  color: $dark;

  .btn-svg {
    background: $primary;

    svg {
      fill: $white;
    }
  }

  &:hover, &:focus {
    color: $white;
    background: $primary;
    border-color: $primary;

    .btn-svg {
      background: $secondary;

      svg {
        fill: $white;
      }
    }
  }
}

.btn-green {
  border: none;
  color: $white;
  background: $primary;

  .btn-svg {
    background: $white;

    svg {
      fill: $text;
    }
  }

  &:hover, &:focus {
    color: $white;
    background: $secondary;
  }
}

.btn-darkgreen {
  border: none;
  color: $white;
  background: $secondary;

  .btn-svg {
    background: $white;

    svg {
      fill: $text;
    }
  }

  &:hover, &:focus {
    color: $text;
    background: $white;

    .btn-svg {
      background: $primary;

      svg {
        fill: $white;
      }
    }
  }
}


.btn-large {
  box-sizing: border-box;
  min-width: 16rem;
  padding: 1.5rem 1rem;
  flex-flow: row;
  align-items: center !important;
  @include font-size(20px);

  .btn-svg {
    margin-top: 0;
    margin-bottom: 0;
  }

  //.btn-text {
  //  width: 100%;
  //}

  @include media-breakpoint-down(md) {
    width: 100%;
    max-width: 100%;
    min-width: 12.5rem;
  }
}

.arrow-btn {
  span {
    width: 2rem;
    height: 2rem;
    background: $white;
    border-radius: 100%;
    transition: $transition-base;

    svg {
      transition: $transition-base;
    }
  }

  &:hover, &:focus {
    span {
      background-color: $secondary;
      transform: rotate(-35deg);

      svg {
        fill: $white;
      }
    }
  }
}


.btn-article {
  padding: 2.5rem 1.5rem 1.5rem 2.5rem;
  background: $white;
  mask: url('/static/masks/mask-event-button.svg');
  mask-size: contain;
  mask-repeat: no-repeat;
  position: absolute;
  right: 0;
  bottom: 0;

  span {
    width: 2rem;
    height: 2rem;
    border: 1px solid $text;
    border-radius: 100%;
    transition: $transition-base;

    svg {
      transition: $transition-base;
    }
  }

  &:hover, &:focus {
    span {
      border-color: $primary;
      background: $primary;
      transform: rotate(-35deg);

      svg {
        fill: $white;
      }
    }
  }
}

.btn-article-light {
  background-color: $light;
}

.btn-file {
  &:hover {
    svg {
      transform: unset;
    }
  }
}

.no-rotate {
  &:hover {
    svg {
      transform: unset;
    }
  }
}

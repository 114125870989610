.event-block-col {
  min-height: 100%;
  display: grid;
  overflow: hidden;

  .event-block-img {
    height: 100%;
  }

  @include media-breakpoint-down(sm) {
    .c-event-date {
      position: unset;
    }
  }
}

.event-block-img {
  aspect-ratio: 5 / 4.5;

  &-mask {
    width: 19.464rem;
    height: 12.117rem;
    background: $white;
    position: absolute;
    right: 0;
    bottom: 0;
    mask: url('/static/masks/mask-event-heading.svg');
    mask-repeat: no-repeat;
    mask-size: auto;
  }
}

main#page-events-detail {
  #event-map {
    width: 100%;
    height: 16.25rem;
  }
}

main#page-events-past, main#page-news-detail {
  .b-category {
    background: $light;
    padding: 0.2rem 1rem 0.4rem 1rem;
    border-radius: 24px;
  }

  .b-author {
    .dot {
      display: block;
      content: '';
      width: 6px;
      min-width: 6px;
      height: 6px;
      min-height: 6px;
      border-radius: 100%;
      background: $primary;
    }

    .b-date {
      &::before {
        display: block;
        content: '';
        width: 6px;
        height: 6px;
        margin-top: 2px;
        border-radius: 100%;
        background: $primary;
      }
    }
  }

  #blog-content-table {
    li {
      margin-bottom: 0.4rem;
    }

    a {
      text-decoration: none;
      font-weight: 300;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  #document-downloads {
    .btn-file{
      min-width: unset;
    }
  }
}

.b-event-contacts {
  a {
    transition: $transition-base;

    svg {
      margin-top: 5px;
      margin-right: 5px;
    }

    &:hover {
      svg {
        fill: $primary;
      }
    }
  }
}

.event-alerts {
  span {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;

    svg {
      margin-top: 2px;
    }
  }

  .text-danger {
    background-color: rgba($danger, 0.1);
    border: 1px solid $danger;
  }

  .text-warning {
    background-color: rgba($warning, 0.1);
    border: 1px solid $warning;
  }
}

.warning-display {
  &-icon {
    background-image: url("../../../static/img/warning-bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10rem;
    @include media-breakpoint-down(md) {
      background-size: 100%;
      img {
        width: 60%;
      }
    }
  }
}

.confirmation-text {
  p {
    margin: 0;
  }
}

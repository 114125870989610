.c-gradient-shape{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 55%;
  @include media-breakpoint-down(xl) {
    width: 45rem;
  }
  height: 100%;
  background: linear-gradient(30deg, $secondary 0%, $primary 50%);
  border-radius: 0 6rem 6rem 0;
}

main#page-search {
  a {
    .btn-article {
      transition: all ease-out 0.4s;
      bottom: 0;
    }

    &:hover {
      .btn-article {

      }
    }
  }

  .b-author {
    .b-date {
      &::before {
        display: block;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: $primary;
      }
    }
  }

  .regular-search{
    span{
      width: 2rem;
      height: 2rem;
      background: $text;
      border-radius: 100%;
      transition: $transition-base;

      svg {
        transition: $transition-base;
      }
    }
    &:hover{
      span {
        background: $primary;
        transform: rotate(-35deg);

        svg {
          fill: $white;
        }
      }
    }
  }
}

.l-widget--cta {
  &-container{
    min-height: 16.5rem;
  }
  &-overlay {
    @extend %coverer;
  }

  img {
    mask: url('/static/masks/mask-cta.svg');
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: right;;
  }
}

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";


// scss-docs-start theme-color-variables
$primary: #589C92;
$secondary: #1D6B70;
$success: $green;
$info: $blue;
$warning: #ff9407;
$danger: $red;
$light: #F4F8FA;
$lighter: #fcfdff;
$dark: #05121E;
$text: #05192B;
$border-color: $gray-200;
// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "lighter": $lighter,
  "dark": $dark,
  "text": $text,
  "gray": $gray-600,
  "border": $border-color,
);

$body-color: $text;

//Links
$link-decoration: none;

// Custom
$container-fluid-max-width: 125rem;
$z-index-cookies: 10000;

// Options
$enable-negative-margins: true;

// Spacing
$spacer: 0.625rem;
$spacers: (
  0: 0,
  1: $spacer,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6,
  8: $spacer * 8,
  10: $spacer * 10,
  12: $spacer * 12,
);

// Grid columns
$grid-gutter-width: 2rem;
$container-padding-x: $grid-gutter-width * 0.625;
$gutters: $spacers;

//fonts
$font-family-sans-serif: "mundial", sans-serif;
$font-family-monospace: "mundial", sans-serif;

// Typography
$h1-font-size: $font-size-base * 3;
$h2-font-size: $font-size-base * 2.375;
$h3-font-size: $font-size-base * 2;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.125;
$h6-font-size: $font-size-base;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  10: 0.625rem,
  12: 0.75rem,
  14: 0.875rem,
  16: 1rem,
  18: 1.125rem,
);

$headings-margin-bottom: $spacer * 2;

// Navbar
$navbar-dark-color: $white;
$navbar-light-color: $black;

$nav-link-font-size: $font-size-base;

$navbar-dark-active-color: $primary;
$navbar-light-active-color: $primary;

$offcanvas-horizontal-width: 500px;

// links

$link-color: $text;
$link-decoration: none;
$link-shade-percentage: 100%;
$link-hover-color: $primary;
$link-hover-decoration: null;


// forms
$input-focus-border-color: none;
$input-focus-box-shadow: none;
$form-check-input-focus-border: none;
$form-check-input-focus-box-shadow: none;
$form-select-focus-border-color: none;
$form-select-focus-box-shadow: none;

$input-padding-y: 0.65rem 1rem;
$input-padding-x: 0.75rem;
$input-line-height: 1;
$input-font-weight: 300;
$input-font-size: 14px;
$input-border-radius: 10px;
$input-focus-border-color: $primary;

$form-select-padding-y: 0.70rem;
$form-select-padding-x: 0.75rem;
$form-select-font-size: $input-font-size;
$form-select-border-radius: $input-border-radius;
$form-select-font-weight: $input-font-weight;

$form-check-input-width: 1.2rem;
$form-check-input-checked-bg-color: $primary;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-focus-border:           $input-focus-border-color;


// progress

$progress-height: 3px;
$progress-bar-bg: $primary;
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width .1s ease;


// tables
$table-cell-vertical-align: center;

.cms-text {
  word-break: break-word;
  font-weight: 200;
  p a:not(.btn) {
    text-decoration: underline;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  ol,
  ul {
    margin-top: $grid-gutter-width * 0.5;
    margin-bottom: $grid-gutter-width * 0.5;
  }
}

.limited-text{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.text-balance {
  text-wrap: balance;
}

.ls-0{
  letter-spacing: 0;
}

.l-widget--image-text{
  img{
    height: 100%;
    aspect-ratio: 5 / 4.5;
  }
  &-mask{
    width: 19.464rem;
    height: 12.117rem;
    background: $white;
    position: absolute;
    right: 0;
    bottom: 0;
    mask: url('/static/masks/mask-event-heading.svg');
    mask-repeat: no-repeat;
    mask-size: auto;
  }
}
